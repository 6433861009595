import React from 'react';
import compassSvg from './compass.svg'

interface ImageComponentProps {
  width?: string | number;
  height?: string | number;
  className?: string;
  style?: React.CSSProperties;
}

const CompassIcon = React.forwardRef<HTMLImageElement, ImageComponentProps>((props: ImageComponentProps, ref) => (
  <img ref={ref} src={compassSvg} alt="Compass" width="80px" height="80px" {...props}/>
));

export default CompassIcon;