import React, {FunctionComponent} from 'react';
import {Menu, MenuProps} from "antd";
import {TableOutlined, HomeOutlined, BarsOutlined, AppstoreOutlined, FlagOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {SelectInfo} from "rc-menu/lib/interface";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppSelector} from "../../store/store";

enum MenuKey {
  Expeditions = 1,
  MainPage,
  Equipment,
  AllEquipment
}

interface Props {
  menuKey?: MenuKey,
  onSelect: (key: MenuKey) => void,
  expeditionName?: string
}

function decodeKey(key: string): MenuKey | undefined {
  return MenuKey[key as keyof typeof MenuKey]
}

function stringKey(key: MenuKey): string {
  return MenuKey[key]
}

const MainMenu: FunctionComponent<Props> = (props) => {
  const {t} = useTranslation('translation', {keyPrefix: 'menu.main'})

  const onSelect = (info: SelectInfo) => {
    const key = decodeKey(info.key)
    if (key) {
      props.onSelect(key)
    }
  }

  const items: MenuProps['items'] = []
  items.push({
    key: stringKey(MenuKey.Expeditions),
    label: t("expeditions"),
    icon: <AppstoreOutlined/>
  })
  if (props.expeditionName) {
    items.push({
      key: "CurrentExpedition",
      label: props.expeditionName,
      icon: <FlagOutlined/>,
      children: [
        {
          key: stringKey(MenuKey.MainPage),
          label: t("mainPage"),
          icon: <HomeOutlined/>
        },
        {
          key: stringKey(MenuKey.Equipment),
          label: t("equipment"),
          icon: <TableOutlined/>
        }
      ]
    })
  }
  items.push({
    key: stringKey(MenuKey.AllEquipment),
    label: t("allEquipment"),
    icon: <BarsOutlined/>
  })

  const selectedKeys = props.menuKey ? [stringKey(props.menuKey)] : []

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={["CurrentExpedition"]}
      selectedKeys={selectedKeys}
      style={{height: '100%', borderRight: 0}}
      items={items}
      onSelect={onSelect}
    />
  );
};

// TODO собрано на костылях ради прототипа дизайна, надо собрать нормально
const MainMenuContainer: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const expeditionId = useAppSelector(state => state.base.lastSelectedExpeditionId)

  const path = location.pathname

  let key: MenuKey = MenuKey.Expeditions
  let expeditionName: string | undefined = expeditionId ? "My expedition" : undefined

  if (path === "/") {
    key = MenuKey.Expeditions
  } else if (path.startsWith("/equipments")) {
    key = MenuKey.AllEquipment
  } else if (path.match(/^\/expeditions\/[\w\d]+$/)) {
    key = MenuKey.MainPage
    expeditionName = "My expedition"
  } else if (path.match(/^\/expeditions\/[\w\d]+\/equipments/)) {
    key = MenuKey.Equipment
    expeditionName = "My expedition"
  }

  const onSelect = (key: MenuKey) => {
    switch (key) {
      case MenuKey.Expeditions:
        navigate("/expeditions", {replace: true})
        break
      case MenuKey.MainPage:
        navigate(`/expeditions/${expeditionId}`, {replace: true})
        break
      case MenuKey.Equipment:
        navigate(`/expeditions/${expeditionId}/equipments`, {replace: true})
        break
      case MenuKey.AllEquipment:
        navigate(`/equipments`, {replace: true})
        break
    }
  }
  return <MainMenu menuKey={key} expeditionName={expeditionName} onSelect={onSelect}/>
}

export {MenuKey};

export default MainMenuContainer;
