import React from 'react';
import styles from './Header.module.css'

const Header: React.FC = () => {
  return (
    <div className="logo" color="white">
      <span className={styles.logo}>ZAVHOZ</span>
    </div>
  )
}

export default Header;
