import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

interface BaseState {
  lastSelectedExpeditionId?: string
}

const initialState: BaseState = {}

const slice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    setLastExpedition: (state, action: PayloadAction<string>) => {
      state.lastSelectedExpeditionId = action.payload
    },
  },
})

export const {setLastExpedition} = slice.actions

export default slice.reducer