import React, { FunctionComponent } from "react";
import FullEquipmentCard from "./FullEquipmentCard";
import { gql } from "api";
import DrawerCard from "common/components/drawer-card/DrawerCard";

type EquipmentCardContextData = (id?: string) => void;

export const EquipmentCardContext =
  React.createContext<EquipmentCardContextData>((id?: string) => {});

export type EquipmentData = gql.EquipmentInput;

// TODO: обобщить и утащить в common
enum CardState {
  Edit = "edit",
  View = "view",
  Saving = "saving",
  Deleting = "deleting",
}

type State = {
  state: CardState | null;
  id: string | null;
  fetchedData: EquipmentData | null;
  modifiedData: EquipmentData | null;
};

const initState: State = {
  state: null,
  id: null,
  fetchedData: null,
  modifiedData: null,
};

type Action<T> = {
  type: T;
};

type NewAction = Action<"newCard">;

type OpenAction = Action<"open"> & {
  data: gql.Equipment;
};

type StartEditAction = Action<"startEdit">;

type CancelEditAction = Action<"cancelEdit">;

type ChangeAction = Action<"change"> & {
  data: EquipmentData;
};

type StartSavingAction = Action<"startSaving">;

type FinishSavingAction = Action<"finishSaving"> & {
  data: gql.Equipment;
};

type SavingErrorAction = Action<"savingError">;

type StartDeletingAction = Action<"startDeleting">;

type FinishDeletingAction = Action<"finishDeleting">;

type DeletingErrorAction = Action<"deletingError">;

type CloseAction = Action<"close">;

type CardAction =
  | NewAction
  | OpenAction
  | StartEditAction
  | CancelEditAction
  | ChangeAction
  | StartSavingAction
  | FinishSavingAction
  | SavingErrorAction
  | StartDeletingAction
  | FinishDeletingAction
  | DeletingErrorAction
  | CloseAction;

function toData(equipment: gql.Equipment): EquipmentData {
  return {
    caption: equipment.caption,
    vendor: equipment.vendor,
    totalWeight: equipment.totalWeight,
  };
}

const emptyData: EquipmentData = {
  caption: "",
  vendor: "",
  totalWeight: null,
};

function cardReducer(state: State, action: CardAction): State {
  switch (action.type) {
    case "newCard":
      return {
        state: CardState.Edit,
        id: null,
        fetchedData: emptyData,
        modifiedData: null,
      };
    case "open":
      return {
        state: CardState.View,
        id: action.data.id,
        fetchedData: toData(action.data),
        modifiedData: null,
      };
    case "startSaving":
      if (state.state !== CardState.Edit) {
        console.error("Invalid state for action", state, action);
        return state;
      }
      return {
        ...state,
        state: CardState.Saving,
      };
    case "finishSaving":
      if (state.state !== CardState.Saving) {
        console.error("Invalid state for action", state, action);
        return state;
      }
      return {
        ...state,
        state: CardState.View,
        id: action.data.id,
        fetchedData: toData(action.data),
        modifiedData: null,
      };
    case "savingError":
      if (state.state !== CardState.Saving) {
        console.error("Invalid state for action", state, action);
        return state;
      }
      return {
        ...state,
        state: CardState.Edit,
      };
    case "startDeleting":
      if (state.state !== CardState.View) {
        console.error("Invalid state for action", state, action);
        return state;
      }
      return {
        ...state,
        state: CardState.Deleting,
      };
    case "finishDeleting":
      if (state.state !== CardState.Deleting) {
        console.error("Invalid state for action", state, action);
        return state;
      }
      return {
        ...state,
        state: null,
        id: null,
        fetchedData: null,
        modifiedData: null,
      };
    case "deletingError":
      if (state.state !== CardState.Deleting) {
        console.error("Invalid state for action", state, action);
        return state;
      }
      return {
        ...state,
        state: CardState.View,
      };
    case "startEdit":
      if (state.state !== CardState.View) {
        console.error("Invalid state for action", state, action);
        return state;
      }
      return {
        ...state,
        state: CardState.Edit,
      };
    case "cancelEdit":
      if (state.state !== CardState.Edit) {
        console.error("Invalid state for action", state, action);
        return state;
      }
      return {
        ...state,
        state: CardState.View,
        modifiedData: null,
      };
    case "change":
      if (state.state !== CardState.Edit) {
        console.error("Invalid state for action", state, action);
        return state;
      }
      return {
        ...state,
        modifiedData: action.data,
      };
    case "close":
      return initState;
    default:
      console.error("Unknown action", action);
      return state;
  }
}

type Props = React.PropsWithChildren<{}>

const EquipmentDrawerCardProvider: FunctionComponent<Props> = ({ children }) => {
  const [state, dispatch] = React.useReducer(cardReducer, initState);

  const [fetchEquipment] = gql.useGetEquipmentLazyQuery();

  const [saveEquipment] = gql.useSaveEquipmentMutation();
  const [deleteEquipment] = gql.useDeleteEquipmentMutation();

  const openCardCallback = React.useCallback(
    (id?: string) => {
      if (id) {
        fetchEquipment({ variables: { id } }).then((result) => {
          if (result.data?.equipment) {
            dispatch({ type: "open", data: result.data.equipment });
          }
        });
      } else {
        dispatch({ type: "newCard" });
      }
    },
    [dispatch, fetchEquipment]
  );

  const startEditCallback = React.useCallback(() => {
    dispatch({ type: "startEdit" });
  }, [dispatch]);

  const cancelEditCallback = React.useCallback(() => {
    dispatch({ type: "cancelEdit" });
  }, [dispatch]);

  const changeCallback = React.useCallback(
    (data: EquipmentData) => {
      dispatch({ type: "change", data });
    },
    [dispatch]
  );

  const saveCallback = React.useCallback(() => {
    if (state.state !== CardState.Edit) {
      console.error("Invalid state for action", state);
      return;
    }
    const data: EquipmentData = {
      caption: state.modifiedData?.caption || state.fetchedData?.caption || "",
      totalWeight:
        state.modifiedData?.totalWeight ||
        state.fetchedData?.totalWeight ||
        null,
      vendor: state.modifiedData?.vendor || state.fetchedData?.vendor || "",
    };
    dispatch({ type: "startSaving" });
    saveEquipment({ variables: { id: state.id, data } }).then((result) => {
      if (result.data?.saveEquipment) {
        dispatch({ type: "finishSaving", data: result.data.saveEquipment });
      } else {
        dispatch({ type: "savingError" });
      }
    });
  }, [dispatch, saveEquipment, state]);

  const deleteCallback = React.useCallback(() => {
    if (state.state !== CardState.View || !state.id) {
      console.error("Invalid state for action", state);
      return;
    }
    dispatch({ type: "startDeleting" });
    deleteEquipment({ variables: { id: state.id } }).then((result) => {
      if (result.data?.deleteEquipment) {
        dispatch({ type: "finishDeleting" });
      } else {
        dispatch({ type: "deletingError" });
      }
    });
  }, [deleteEquipment, dispatch, state]);

  const closeCallback = React.useCallback(() => {
    dispatch({ type: "close" });
  }, [dispatch]);

  const caption =
    state.modifiedData?.caption ||
    state.fetchedData?.caption ||
    "New equipment";
  const modified = state.modifiedData !== null;

  return (
    <EquipmentCardContext.Provider value={openCardCallback}>
      {children}
      <DrawerCard
        state={state.state as any /* TODO: fix this shit */ }
        hasChanges={modified}
        caption={caption}
        onSave={saveCallback}
        onEdit={startEditCallback}
        onDelete={deleteCallback}
        onCancel={cancelEditCallback}
        onClose={closeCallback}
      >
        {state.state === null ? null : ( // нужно чтобы сбрасывать состояние карточки при открытии, тут много других побочных эффектов, надо починить
          <FullEquipmentCard
            equipment={state.fetchedData || undefined}
            editable={state.state === CardState.Edit}
            onChange={changeCallback}
          />
        )}
      </DrawerCard>
    </EquipmentCardContext.Provider>
  );
};

export default EquipmentDrawerCardProvider;
