import React from "react";
import { Badge, Card, Descriptions } from "antd";
import { gql } from "api";
import LoadingScreen from "common/components/LoadingScreen";

interface Props {
  id: string;
}

const tabList = [
  {
    key: "1",
    tab: "Общее",
  },
  {
    key: "2",
    tab: "Статистика",
  },
  {
    key: "3",
    tab: "Члены",
  },
];

export interface Data {
  id: string;
  caption: string;
  //start?: Date,
  //end?: Date,
}

const ExpeditionScreen: React.FC<Props> = ({ id }) => {
  const { data, loading, error } = gql.useGetExpeditionQuery({
    variables: { id },
  });

  if (error) return <div>Ошибка: {error.message}</div>; // TODO: нормальный экран ошибок
  if (loading) return <LoadingScreen />;

  const expeditions: Data | null | undefined = data?.expedition;

  return (
    <Card title={expeditions?.caption} tabList={tabList}>
      <Descriptions bordered>
        <Descriptions.Item label="Automatic Renewal">YES</Descriptions.Item>
        <Descriptions.Item label="Order time">
          2018-04-24 18:00:00
        </Descriptions.Item>
        <Descriptions.Item label="Usage Time" span={2}>
          2019-04-24 18:00:00
        </Descriptions.Item>
        <Descriptions.Item label="Status" span={3}>
          <Badge status="processing" text="Running" />
        </Descriptions.Item>
        <Descriptions.Item label="Negotiated Amount">$80.00</Descriptions.Item>
        <Descriptions.Item label="Discount">$20.00</Descriptions.Item>
        <Descriptions.Item label="Official Receipts">$60.00</Descriptions.Item>
        <Descriptions.Item label="Config Info">
          Data disk type: MongoDB
          <br />
          Database version: 3.4
          <br />
          Package: dds.mongo.mid
          <br />
          Storage space: 10 GB
          <br />
          Replication factor: 3
          <br />
          Region: East China 1<br />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default ExpeditionScreen;
