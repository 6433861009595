import React from "react";
import EquipmentGrid from "./EquipmentGrid";
import GridControlPanel from "./GridControlPanel";
import LoadingScreen from "../common/components/LoadingScreen";
import { gql } from "api";
import EquipmentDrawerCardProvider from "./EquipmentDrawerCardProvider";
import { EquipmentCardContext } from "./EquipmentDrawerCardProvider";

interface Props {}

const EquipmentGridControlPanel: React.FC = () => {
  const openCard = React.useContext(EquipmentCardContext);
  return (
    <GridControlPanel onCreate={() => openCard()} />
  )
}

const EquipmentsScreen: React.FC<Props> = (props) => {
  const { loading, error, data } = gql.useGetAllEquipmentsQuery();

  const eqs = data?.equipments;
  if (error) return <div>Ошибка: {error.message}</div>;
  if (loading) return <LoadingScreen />;

  return (
    <div className="site-card-wrapper">
      <EquipmentDrawerCardProvider>
        <EquipmentGridControlPanel/>
        <EquipmentGrid items={eqs as any /*TODO: fix this shit*/} /> 
      </EquipmentDrawerCardProvider>
    </div>
  );
};

export default EquipmentsScreen;
