import React, { FunctionComponent } from "react";
import { Drawer, Modal, Tooltip } from "antd";
import { ExclamationCircleOutlined, RightOutlined } from "@ant-design/icons";
import { CardState } from "./types";
import CardControlPanel from "./CardControlPanel";

type CardController = React.PropsWithChildren & {
  state: CardState | null;
  hasChanges: boolean;
  caption: string;
  onSave: () => void;
  onDelete: () => void;
  onClose: () => void;
  onEdit: () => void;
  onCancel: () => void;
};

const DrawerCard: FunctionComponent<CardController> = (props) => {
  const { hasChanges, onClose, onCancel, onDelete } = props;
  const closeCallback = React.useCallback(() => {
    if (hasChanges) {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: "Редактирование не закончено",
        content: "Хотите сбросить изменения?",
        centered: true,
        onOk: onClose,
      });
    } else {
      onClose();
    }
  }, [hasChanges, onClose]);

  const cancelEditCallback = React.useCallback(() => {
    if (hasChanges) {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: "Редактирование не закончено",
        content: "Хотите сбросить изменения?",
        centered: true,
        onOk: onCancel,
      });
    } else {
      onCancel();
    }
  }, [hasChanges, onCancel]);

  const deleteCallback = React.useCallback(() => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Вы уверены, что хотите удалить?",
      content: "Операция необратима",
      centered: true,
      onOk: onDelete,
    });
  }, [onDelete]);

  const controlPanel = (
    <CardControlPanel
      state={props.state || CardState.View}
      hasChanges={props.hasChanges}
      onSave={props.onSave}
      onDelete={deleteCallback}
      onEdit={props.onEdit}
      onCancel={cancelEditCallback}
    />
  );

  return (
    <Drawer
      width={500}
      placement="right"
      closable={true}
      closeIcon={
        <Tooltip placement="bottom" title={"Close"}>
          <RightOutlined />
        </Tooltip>
      }
      onClose={closeCallback}
      visible={props.state !== null}
      title={props.caption}
      bodyStyle={{ paddingBottom: 80 }}
      extra={controlPanel}
    >
      {props.children}
    </Drawer>
  );
};

export default DrawerCard;
