export enum CardState {
  View = "view",
  Edit = "edit",
  Saving = "saving",
  Delete = "deleting",
}

export function isProcessing(state: CardState) {
  return state === CardState.Saving || state === CardState.Delete
}
