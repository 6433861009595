import React from "react";
import ExpeditionsGrid from "./ExpeditionsGrid";
import LoadingScreen from "../common/components/LoadingScreen";
import { gql } from "api";

const ExpeditionsScreen: React.FC<{}> = (props) => {
  const { data, loading, error } = gql.useGetAllExpeditionsQuery({});

  if (error) return <div>Ошибка: {error.message}</div>; // TODO: нормальный экран ошибок
  if (loading) return <LoadingScreen />;

  const expeditions = (data?.expeditions || []) as gql.Expedition[];

  return (
    <div className="site-card-wrapper">
      <ExpeditionsGrid items={expeditions} />
    </div>
  );
};

export default ExpeditionsScreen;
