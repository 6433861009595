import React from 'react';
import CompassIcon from "../icons/CompassIcon";
import styles from "./LoadingScreen.module.css"
import clsx from "clsx";
import RenderDelay from './RenderDelay';

interface LoadingScreenProps {
  label?: string;
}

const LoadingScreen: React.FC<LoadingScreenProps> = (props: LoadingScreenProps) => {
  let labelComponent = props.label ? <div className={clsx(styles.inner, styles.label)}>{props.label}</div> : null
  return (
    <div className={styles.outer}>
      <RenderDelay delay={500}>
      <div className={clsx(styles.inner, styles.icon)}>
        <CompassIcon className="animation-spin"/>
      </div>
      {labelComponent}
      </RenderDelay>
    </div>
  )
}

export default LoadingScreen