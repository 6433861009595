import React from "react";

type Props = React.PropsWithChildren<{
  visible: boolean;
}>

/** 
 * Renders children only if visible is true. 
 * Allow simplifying code for conditional rendering.
 */
const Conditional: React.FC<Props> = ({ visible, children }) => (
  <>{visible ? children : null}</>
);

export default Conditional;
