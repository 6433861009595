import React, { FunctionComponent } from "react";
import { Button, Space } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LikeOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { CardState, isProcessing } from "./types";

type ButtonState = "enabled" | "disabled" | "loading";

type ButtonProps = {
  onClick: () => void;
  state: ButtonState;
};

const SaveButton: FunctionComponent<ButtonProps> = React.memo((props) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={props.state === "enabled" ? props.onClick : undefined}
      type="primary"
      icon={<LikeOutlined />}
      disabled={props.state === "disabled"}
      loading={props.state === "loading"}
    >
      {t("form.button.Save", "Save")}
    </Button>
  );
});

const DeleteButton: FunctionComponent<ButtonProps> = React.memo((props) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={props.state === "enabled" ? props.onClick : undefined}
      danger
      icon={<DeleteOutlined />}
      disabled={props.state === "disabled"}
      loading={props.state === "loading"}
    >
      {t("form.button.Delete", "Delete")}
    </Button>
  );
});

const EditButton: FunctionComponent<ButtonProps> = React.memo((props) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={props.state === "enabled" ? props.onClick : undefined}
      icon={<EditOutlined />}
      disabled={props.state === "disabled"}
      loading={props.state === "loading"}
    >
      {t("form.button.Edit", "Edit")}
    </Button>
  );
});

const CancelButton: FunctionComponent<ButtonProps> = React.memo((props) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={props.state === "enabled" ? props.onClick : undefined}
      disabled={props.state === "disabled"}
      loading={props.state === "loading"}
    >
      {t("form.button.Cancel", "Cancel")}
    </Button>
  );
});

type ControlPanelProps = {
  state: CardState;
  hasChanges: boolean;
  onSave: () => void;
  onDelete: () => void;
  onEdit: () => void;
  onCancel: () => void;
};

const ControlPanel: FunctionComponent<ControlPanelProps> = ({
  state,
  hasChanges,
  onSave,
  onCancel,
  onDelete,
  onEdit,
}) => {
  let buttons: React.ReactNode[] = [];
  const isProcess = isProcessing(state);
  if (state === CardState.View) {
    buttons.push(
      <EditButton
        key="edit"
        state={isProcess ? "disabled" : "enabled"}
        onClick={onEdit}
      />,
      <DeleteButton
        key="delete"
        state={isProcess ? "loading" : "enabled"}
        onClick={onDelete}
      />
    );
  } else {
    let saveState: ButtonState = "disabled";
    if (isProcess) saveState = "loading";
    else if (hasChanges) saveState = "enabled";
    buttons.push(
      <SaveButton key="save" state={saveState} onClick={onSave} />,
      <CancelButton
        key="cancel"
        state={isProcess ? "disabled" : "enabled"}
        onClick={onCancel}
      />
    );
  }
  return <Space>{buttons}</Space>;
};

export default ControlPanel;
