import React from "react";
import { Card, Col, List, Row } from "antd";
import Conditional from "../common/components/Conditional";
import { Link } from "react-router-dom";

interface Data {
  id: string;
  caption: string;
  //start?: string;
  //end?: string;
}

interface Props {
  items: Data[];
}

function card(expedition: Data): React.ReactNode {
  const data: String[] = [] // [expedition.start, expedition.end].filter((s) => !!s);

  return (
    <Col key={expedition.id} span={4}>
      <Link to={"/expeditions/" + expedition.id}>
        <Card
          size="small"
          bordered={true}
          //extra={<a href="#">Edit</a>}
          title={expedition.caption}
          style={{ height: "100%" }}
          hoverable
        >
          <Conditional visible={data.length > 0}>
            <List
              size="small"
              dataSource={data}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Conditional>
        </Card>
      </Link>
    </Col>
  );
}

const ExpeditionsGrid: React.FC<Props> = ({ items }) => {
  const list = items.map((eq) => card(eq));
  return (
    <Row gutter={[16, 16]} wrap align="stretch">
      {list}
    </Row>
  );
};

export default ExpeditionsGrid;
