import React, {useState} from 'react';
import {Button, Card, Col, Row, Space} from "antd";
import {AppstoreOutlined, ArrowDownOutlined, ArrowUpOutlined, MenuOutlined, PlusOutlined} from '@ant-design/icons';

interface Props {
  onCreate: () => any
}


const GridControlPanel: React.FC<Props> = ({onCreate}) => {
  const [sortReverse, setSortReverse] = useState<boolean>(false);
  const [tableView, setTableView] = useState<boolean>(false);
  return (
    <Card size="small" style={{marginBottom: "20px", padding: "0 10px"}}>
      <Row wrap>
        <Col flex="auto">
          <Button type="primary" icon={<PlusOutlined/>} onClick={onCreate}>
            Create
          </Button>
        </Col>
        <Col>
          <Space>
            <Button
              shape="circle"
              icon={tableView ? <AppstoreOutlined/> : <MenuOutlined/>}
              onClick={() => setTableView(!tableView)}
            />
            <Button
              shape="circle"
              icon={sortReverse ? <ArrowDownOutlined/> : <ArrowUpOutlined/>}
              onClick={() => setSortReverse(!sortReverse)}
            />
          </Space>
        </Col>
      </Row>
    </Card>
  )
}

export default GridControlPanel;
