import {Breadcrumb, Layout} from 'antd';
import React from 'react';
import Header from './Header'
import MainMenu from "./MainMenu";
import styles from "./MainLayout.module.css"

type Props = {
  children?: React.ReactNode;
}

const MainLayout: React.FC<Props> = ({children}) => (
  <Layout>
    <Layout.Header>
      <Header/>
    </Layout.Header>
    <Layout>
      <Layout.Sider width={240} className={styles.sider}>
        <MainMenu/>
      </Layout.Sider>
      <Layout>
        {/*TODO вот это надо сделать нормально и вынести в отдельный компонент*/}
        <Breadcrumb className={styles.breadcrumb}>
          <Breadcrumb.Item>Это</Breadcrumb.Item>
          <Breadcrumb.Item>Надо</Breadcrumb.Item>
          <Breadcrumb.Item>Исправить</Breadcrumb.Item>
        </Breadcrumb>
        <Layout.Content className={styles.content}>
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  </Layout>
);

export default MainLayout;