import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { ConfigProvider, ThemeConfig } from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import { BrowserRouter as Router, useMatch } from "react-router-dom";
import { store, useAppDispatch } from "../store/store";
import { Provider as StoreProvider } from "react-redux";
import { setLastExpedition } from "../store/baseSlice";
import { ApolloProvider } from "@apollo/client";
import client from "../api/client";

interface Props {
  children: React.ReactNode;
}

// TODO это кривой костыль и надо сделать нормально
const HistoryListener = () => {
  const res = useMatch("/expeditions/:id");
  const dispatch = useAppDispatch();
  const id = res?.params["id"];
  if (id) {
    dispatch(setLastExpedition(id));
  }
  return null;
};

const theme: ThemeConfig = {
  token: {
    colorBgBase: "#f8f8f7",
  },
};

const GeneralProvider: React.FC<Props> = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <StoreProvider store={store}>
        <I18nextProvider i18n={i18n}>
          <ConfigProvider locale={ruRU} componentSize="middle" theme={theme}>
            <Router>
              <HistoryListener />
              {children}
            </Router>
          </ConfigProvider>
        </I18nextProvider>
      </StoreProvider>
    </ApolloProvider>
  );
};

export default GeneralProvider;
