import {Form, Input, InputNumber, List} from 'antd';
import React from 'react';
import { EquipmentData } from './EquipmentDrawerCardProvider';

export interface Props {
  equipment?: EquipmentData
  editable: boolean,
  onChange: (eq: EquipmentData) => void
}

interface Field {
  name: string,
  type?: "text" | "number",
  required?: boolean,
}

const fieldsProps: Field[] = [
  {name: "caption", required: true},
  {name: "vendor"},
  {name: "totalWeight", type: "number"},
]

const FullEquipmentCard: React.FC<Props> = ({equipment, editable, onChange}) => {
  const [form] = Form.useForm<EquipmentData>();
  console.log("FullEquipmentCard", equipment)
  // if (!editable && equipment) {
  //   form.setFieldsValue(equipment)
  // }
  const fields = fieldsProps.map(({name, required, type}) =>
    <List.Item key={name} >
      <Form.Item
        label={<b>{name}</b>}
        name={name}
        //help={false}
        style={{marginBottom: 0, width: "100%"}}
        //required={!!required && editable}
        rules={[{required: !!required && editable}]}
      >
        {
          type === "number" ?
            <InputNumber bordered={editable} style={{color: "black", background: "white", cursor: "default"}}/> :
            <Input bordered={editable} style={{color: "black", cursor: "default"}}/>
        }
      </Form.Item>
    </List.Item>
  )

  return (
    <Form<EquipmentData>
      form={form}
      name="equipment-card"
      labelCol={{span: 5}}
      wrapperCol={{span: 19}}
      initialValues={equipment}
      onValuesChange={(x, y) => onChange(y)}
      autoComplete="off"
      disabled={!editable}
      preserve={false}
    >
      <List size="small">
        {fields}
      </List>
    </Form>
  )
}

export default FullEquipmentCard;