import React, { useState, useEffect } from 'react';

type RenderDelayProps = React.PropsWithChildren<{
  delay: number;
}>

/**
 * Renders children after timeout.
 * Usage:
 * <RenderDelay timeout={1000}>
 *  <div>Rendered after 1 second</div>
 * </RenderDelay>
 */ 
const RenderDelay: React.FC<RenderDelayProps> = ({ delay, children }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      setShouldRender(true);
    }, delay);

    return () => clearTimeout(t);
  }, [delay]);

  return shouldRender ? children : null;
};

export default RenderDelay;