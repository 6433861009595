import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  ObjectId: { input: string; output: string; }
};

export type Equipment = {
  __typename?: 'Equipment';
  caption: Scalars['String']['output'];
  id: Scalars['ObjectId']['output'];
  totalWeight?: Maybe<Scalars['Float']['output']>;
  typeId?: Maybe<Scalars['ObjectId']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
};

export type EquipmentInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  totalWeight?: InputMaybe<Scalars['Float']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
};

export type Expedition = {
  __typename?: 'Expedition';
  caption: Scalars['String']['output'];
  end?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectId']['output'];
  start?: Maybe<Scalars['DateTime']['output']>;
};

export type RootMutation = {
  __typename?: 'RootMutation';
  createExpedition?: Maybe<Expedition>;
  deleteEquipment?: Maybe<Scalars['Boolean']['output']>;
  deleteExpedition?: Maybe<Scalars['Boolean']['output']>;
  saveEquipment?: Maybe<Equipment>;
  updateExpedition?: Maybe<Expedition>;
};


export type RootMutationCreateExpeditionArgs = {
  caption?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};


export type RootMutationDeleteEquipmentArgs = {
  id: Scalars['ObjectId']['input'];
};


export type RootMutationDeleteExpeditionArgs = {
  id: Scalars['ObjectId']['input'];
};


export type RootMutationSaveEquipmentArgs = {
  data: EquipmentInput;
  id?: InputMaybe<Scalars['ObjectId']['input']>;
};


export type RootMutationUpdateExpeditionArgs = {
  caption?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ObjectId']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RootQuery = {
  __typename?: 'RootQuery';
  equipment?: Maybe<Equipment>;
  equipments?: Maybe<Array<Maybe<Equipment>>>;
  expedition?: Maybe<Expedition>;
  expeditions?: Maybe<Array<Maybe<Expedition>>>;
  ping?: Maybe<Scalars['String']['output']>;
};


export type RootQueryEquipmentArgs = {
  id: Scalars['ObjectId']['input'];
};


export type RootQueryExpeditionArgs = {
  id: Scalars['ObjectId']['input'];
};

export type FullEquipmentFragment = { __typename?: 'Equipment', id: string, caption: string, vendor?: string | null, totalWeight?: number | null };

export type GetEquipmentQueryVariables = Exact<{
  id: Scalars['ObjectId']['input'];
}>;


export type GetEquipmentQuery = { __typename?: 'RootQuery', equipment?: { __typename?: 'Equipment', id: string, caption: string, vendor?: string | null, totalWeight?: number | null } | null };

export type GetAllEquipmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllEquipmentsQuery = { __typename?: 'RootQuery', equipments?: Array<{ __typename?: 'Equipment', id: string, caption: string, vendor?: string | null, totalWeight?: number | null } | null> | null };

export type SaveEquipmentMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ObjectId']['input']>;
  data: EquipmentInput;
}>;


export type SaveEquipmentMutation = { __typename?: 'RootMutation', saveEquipment?: { __typename?: 'Equipment', id: string, caption: string, vendor?: string | null, totalWeight?: number | null } | null };

export type DeleteEquipmentMutationVariables = Exact<{
  id: Scalars['ObjectId']['input'];
}>;


export type DeleteEquipmentMutation = { __typename?: 'RootMutation', deleteEquipment?: boolean | null };

export type ExpeditionHeadFragment = { __typename?: 'Expedition', id: string, caption: string, start?: any | null, end?: string | null };

export type GetAllExpeditionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllExpeditionsQuery = { __typename?: 'RootQuery', expeditions?: Array<{ __typename?: 'Expedition', id: string, caption: string, start?: any | null, end?: string | null } | null> | null };

export type GetExpeditionQueryVariables = Exact<{
  id: Scalars['ObjectId']['input'];
}>;


export type GetExpeditionQuery = { __typename?: 'RootQuery', expedition?: { __typename?: 'Expedition', id: string, caption: string, start?: any | null, end?: string | null } | null };

export const FullEquipmentFragmentDoc = gql`
    fragment FullEquipment on Equipment {
  id
  caption
  vendor
  totalWeight
}
    `;
export const ExpeditionHeadFragmentDoc = gql`
    fragment ExpeditionHead on Expedition {
  id
  caption
  start
  end
}
    `;
export const GetEquipmentDocument = gql`
    query GetEquipment($id: ObjectId!) {
  equipment(id: $id) {
    ...FullEquipment
  }
}
    ${FullEquipmentFragmentDoc}`;

/**
 * __useGetEquipmentQuery__
 *
 * To run a query within a React component, call `useGetEquipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquipmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEquipmentQuery(baseOptions: Apollo.QueryHookOptions<GetEquipmentQuery, GetEquipmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEquipmentQuery, GetEquipmentQueryVariables>(GetEquipmentDocument, options);
      }
export function useGetEquipmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEquipmentQuery, GetEquipmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEquipmentQuery, GetEquipmentQueryVariables>(GetEquipmentDocument, options);
        }
export type GetEquipmentQueryHookResult = ReturnType<typeof useGetEquipmentQuery>;
export type GetEquipmentLazyQueryHookResult = ReturnType<typeof useGetEquipmentLazyQuery>;
export type GetEquipmentQueryResult = Apollo.QueryResult<GetEquipmentQuery, GetEquipmentQueryVariables>;
export const GetAllEquipmentsDocument = gql`
    query GetAllEquipments {
  equipments {
    ...FullEquipment
  }
}
    ${FullEquipmentFragmentDoc}`;

/**
 * __useGetAllEquipmentsQuery__
 *
 * To run a query within a React component, call `useGetAllEquipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEquipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEquipmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEquipmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEquipmentsQuery, GetAllEquipmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEquipmentsQuery, GetAllEquipmentsQueryVariables>(GetAllEquipmentsDocument, options);
      }
export function useGetAllEquipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEquipmentsQuery, GetAllEquipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEquipmentsQuery, GetAllEquipmentsQueryVariables>(GetAllEquipmentsDocument, options);
        }
export type GetAllEquipmentsQueryHookResult = ReturnType<typeof useGetAllEquipmentsQuery>;
export type GetAllEquipmentsLazyQueryHookResult = ReturnType<typeof useGetAllEquipmentsLazyQuery>;
export type GetAllEquipmentsQueryResult = Apollo.QueryResult<GetAllEquipmentsQuery, GetAllEquipmentsQueryVariables>;
export const SaveEquipmentDocument = gql`
    mutation SaveEquipment($id: ObjectId, $data: EquipmentInput!) {
  saveEquipment(id: $id, data: $data) {
    ...FullEquipment
  }
}
    ${FullEquipmentFragmentDoc}`;
export type SaveEquipmentMutationFn = Apollo.MutationFunction<SaveEquipmentMutation, SaveEquipmentMutationVariables>;

/**
 * __useSaveEquipmentMutation__
 *
 * To run a mutation, you first call `useSaveEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEquipmentMutation, { data, loading, error }] = useSaveEquipmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<SaveEquipmentMutation, SaveEquipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEquipmentMutation, SaveEquipmentMutationVariables>(SaveEquipmentDocument, options);
      }
export type SaveEquipmentMutationHookResult = ReturnType<typeof useSaveEquipmentMutation>;
export type SaveEquipmentMutationResult = Apollo.MutationResult<SaveEquipmentMutation>;
export type SaveEquipmentMutationOptions = Apollo.BaseMutationOptions<SaveEquipmentMutation, SaveEquipmentMutationVariables>;
export const DeleteEquipmentDocument = gql`
    mutation DeleteEquipment($id: ObjectId!) {
  deleteEquipment(id: $id)
}
    `;
export type DeleteEquipmentMutationFn = Apollo.MutationFunction<DeleteEquipmentMutation, DeleteEquipmentMutationVariables>;

/**
 * __useDeleteEquipmentMutation__
 *
 * To run a mutation, you first call `useDeleteEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEquipmentMutation, { data, loading, error }] = useDeleteEquipmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEquipmentMutation, DeleteEquipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEquipmentMutation, DeleteEquipmentMutationVariables>(DeleteEquipmentDocument, options);
      }
export type DeleteEquipmentMutationHookResult = ReturnType<typeof useDeleteEquipmentMutation>;
export type DeleteEquipmentMutationResult = Apollo.MutationResult<DeleteEquipmentMutation>;
export type DeleteEquipmentMutationOptions = Apollo.BaseMutationOptions<DeleteEquipmentMutation, DeleteEquipmentMutationVariables>;
export const GetAllExpeditionsDocument = gql`
    query getAllExpeditions {
  expeditions {
    ...ExpeditionHead
  }
}
    ${ExpeditionHeadFragmentDoc}`;

/**
 * __useGetAllExpeditionsQuery__
 *
 * To run a query within a React component, call `useGetAllExpeditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllExpeditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllExpeditionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllExpeditionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllExpeditionsQuery, GetAllExpeditionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllExpeditionsQuery, GetAllExpeditionsQueryVariables>(GetAllExpeditionsDocument, options);
      }
export function useGetAllExpeditionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllExpeditionsQuery, GetAllExpeditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllExpeditionsQuery, GetAllExpeditionsQueryVariables>(GetAllExpeditionsDocument, options);
        }
export type GetAllExpeditionsQueryHookResult = ReturnType<typeof useGetAllExpeditionsQuery>;
export type GetAllExpeditionsLazyQueryHookResult = ReturnType<typeof useGetAllExpeditionsLazyQuery>;
export type GetAllExpeditionsQueryResult = Apollo.QueryResult<GetAllExpeditionsQuery, GetAllExpeditionsQueryVariables>;
export const GetExpeditionDocument = gql`
    query getExpedition($id: ObjectId!) {
  expedition(id: $id) {
    ...ExpeditionHead
  }
}
    ${ExpeditionHeadFragmentDoc}`;

/**
 * __useGetExpeditionQuery__
 *
 * To run a query within a React component, call `useGetExpeditionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpeditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpeditionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExpeditionQuery(baseOptions: Apollo.QueryHookOptions<GetExpeditionQuery, GetExpeditionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExpeditionQuery, GetExpeditionQueryVariables>(GetExpeditionDocument, options);
      }
export function useGetExpeditionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExpeditionQuery, GetExpeditionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExpeditionQuery, GetExpeditionQueryVariables>(GetExpeditionDocument, options);
        }
export type GetExpeditionQueryHookResult = ReturnType<typeof useGetExpeditionQuery>;
export type GetExpeditionLazyQueryHookResult = ReturnType<typeof useGetExpeditionLazyQuery>;
export type GetExpeditionQueryResult = Apollo.QueryResult<GetExpeditionQuery, GetExpeditionQueryVariables>;