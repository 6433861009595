import React from 'react';
import './styles/index.css';
import {useTranslation} from "react-i18next";

import LoadingScreen from "../common/components/LoadingScreen";
import MainLayout from "./layout/MainLayout";
import GeneralProvider from "./GeneralProvider";
import EquipmentsScreen from "../equipment/EquipmentsScreen";
import {
  Routes,
  Route,
  Navigate,
  useParams
} from "react-router-dom";
import ExpeditionsScreen from "../expeditions/ExpeditionsScreen";
import ExpeditionScreen from "../expeditions/ExpeditionScreen";


function Main() {
  let {t} = useTranslation()
  return (
    <LoadingScreen label={t('inDevelopment')}/>
  )
}

const ParamWrapper = (props: {elem: React.FC<{id: string}>}) => {
  let { id } = useParams();
  const Elem = props.elem
  if(id) {
    return <Elem id={id}/>
  } else{
    return null
  }
}

function App() {
  return (
    <React.Suspense>
      <GeneralProvider>
        <MainLayout>
          <Routes>
            <Route path="/" element={<Navigate to="/expeditions" replace={true} />}/>
            <Route path="expeditions" element={<ExpeditionsScreen/>}/>
            <Route path="expeditions/:id" element={<ParamWrapper elem={ExpeditionScreen}/>}/>
            <Route path="equipments" element={<EquipmentsScreen/>}/>
            <Route path="*" element={<Main/>}/>
          </Routes>
        </MainLayout>
      </GeneralProvider>
    </React.Suspense>
  )
}

export default App;
