import { ApolloClient, InMemoryCache } from "@apollo/client";

const backendUri =
  window.location.protocol + "//" + window.location.host + "/graphql";

const client = new ApolloClient({
  uri: backendUri,
  cache: new InMemoryCache(),
});

export default client;
