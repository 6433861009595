import React from "react";
import { Card, Col, List, Row } from "antd";
import Conditional from "../common/components/Conditional";
import { EquipmentCardContext } from "./EquipmentDrawerCardProvider";

interface Data {
  id: string;
  code?: string;
  caption?: string;
  vendor?: string;
  type?: string;
  totalWeight?: number;
}

function card(
  equipment: Data,
  onClick: (id: string) => void
): React.ReactNode {
  const data = [
    equipment.code ? equipment.code : null,
    equipment.vendor ? equipment.vendor : null,
    equipment.type ? "Тип: " + equipment.type : null,
    equipment.totalWeight ? "Вес: " + equipment.totalWeight : null,
  ].filter((s) => !!s);

  return (
    <Col key={equipment.id} span={4}>
      <Card
        size="small"
        bordered={true}
        //extra={<a href="#">Edit</a>}
        title={equipment.caption}
        onClick={() => onClick(equipment.id)}
        style={{ height: "100%" }}
        hoverable
      >
        <Conditional visible={data.length > 0}>
          <List
            size="small"
            dataSource={data}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Conditional>
      </Card>
    </Col>
  );
}

interface Props {
  items: Data[];
}

const EquipmentGrid: React.FC<Props> = ({ items }) => {
  const openCard = React.useContext(EquipmentCardContext);
  const list = items.map((eq) => card(eq, openCard));
  return (
    <Row gutter={[16, 16]} wrap align="stretch">
      {list}
    </Row>
  );
};

export default EquipmentGrid;
